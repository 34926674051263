import React, { Component } from "react";
import FreeNav from "./FreeNav";
import FullNav from "./FullNav";
import Config from "./../../Config";
import UserHelper from "./../../Utils/Helpers/UserHelper";
import ClientHelper from "./../../Utils/Helpers/ClientHelper";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: undefined
        };
    }

    componentDidMount() {
        var user = UserHelper.getUser();
        var client = ClientHelper.getClient();

        if (user.logo) {
            this.setState({
                logo: Config.logo.userPath + "desktopweb/lib/img/" + user.logo
            });
        } else {
            this.setState({
                logo: Config.logo.path + client.logo
            });
        }
    }

    render() {
        let nav = null;

        if (ClientHelper.getClient().setup.type !== "full") {
            nav = <FreeNav path={this.props.path} />;
        } else {
            nav = <FullNav path={this.props.path} submenu={this.props.submenu} {...this.props} ref="fullNav" />;
        }

        return (
            <header className="App-header">
                <div className="logo col-sm-3 col-md-3 col-lg-2 navbar navbar-default navbar-fixed-top">
                    <img src={this.state.logo} className="App-logo" alt="logo" />
                </div>
                <div className="row">
                    <div className="tool-bar col-sm-9 col-md-9 col-lg-10 navbar navbar-default navbar-fixed-top">
                        {nav} {this.props.children}
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
